//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import QrcodeVue from 'qrcode.vue'
import i18n from '@/vueI18n';
// const language = getLanguageCode()
import { routerAsync } from '@/app-module';
import Printer from '@/shared/plugins/printer';


export default {
  name: 'app-print-pdf-page',
  props: ['data', 'sellerName', 'sellerPhone', 'doPrint'],
  
  components: {
    // QrcodeVue,
  },

  data() {
    return {
      pagination: {
        rowsPerPage: 10,
        // rowsNumber: xx if getting data from a server
      },
      show: true,
      columns: [
        {
          name: 'qrCode',
          field: 'qrCode',
          label: 'entities.importer.fields.qrCode',
          align: 'center',
        },
        {
          name: 'serialNumber',
          field: 'serialNumber',
          label: 
            'entities.importer.fields.serialNumber',
          align: 'center',
        },
        {
          name: 'name',
          field: 'itemDetails',
          label: 'إسم المنتج', //i18n('entities.importer.fields.itemName'),
          align: 'center',
        },
      ],
    };
  },

  async mounted() {
    if (this.doPrint) {
      setTimeout(async () => {
        await this.print();
        this.$emit('close');
      }, 1000);
    }
  },
  computed:{
    language(){
      return i18n.locale
    }
  },

  methods: {
    print() {
      console.log('heyyyy');
      // this.$htmlToPaper("printMe");
        Printer.doPrint('printMe');

      // let loopsNum = Math.ceil(this.data / 10) 
      // for (let index = 0; index < loopsNum; index++) {
      //   Printer.doPrint(`printMe-${index}`);
      // }
    },
     i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      routerAsync().push('/export');
    },
  },
};
