//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ExporterModel } from '@/modules/exporter/exporter-model';
import { ExporterPermissions } from '@/modules/exporter/exporter-permissions';
import i18n from '@/vueI18n';
import moment from 'moment';
import PrintPdfPage from '@/modules/exporter/components/bills/print-pdf-page';
import  qrcode  from "qrcode";
// import TableViewPage from '@/shared/components/table-view-page.vue';
// import QrcodeVue from 'qrcode.vue'
// import Printer from '@/shared/plugins/printer';
const { fields } = ExporterModel;

export default {
  name: 'app-bills-list-table',

  components: {
    // [TableViewPage.name]: TableViewPage,
    // QrcodeVue,
    [PrintPdfPage.name]:PrintPdfPage
  },

  created() {
    Object.defineProperty(Array.prototype, 'chunk_inefficient', {
      value: function(chunkSize) {
        var array = this;
        return [].concat.apply([],
          array.map(function(elem, i) {
            return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
          })
        );
      }
    });
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter:'',
      selection: [],
      print:false,
      columns: [
        {
          name: 'id',
          field: 'id',
          label: '#',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'name',
          field: 'name',
          label: 'common.distributorName',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'entities.exporter.fields.billDate',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center',
        },
      ],
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },

  asyncComputed:{
    async printingData() {
      if(this.selectedRow){
        const items = []
        for (let index = 0; index < this.selectedRow.items.length; index++) {
          let element = this.selectedRow.items[index];
          element['qrImg'] = await qrcode.toDataURL(element.qrCode)
          items.push(element)
        }
        return items
        // return this.selectedRow.items.map(item => {
        //   return {
        //     name: item.itemId ,
        //     serialNumber: item.serialNumber,
        //     qrCode: item.qrCode,
        //     img: await qrcode.toDataURL('http://asyncawait.net')
        //   }
        // })
      }
      return []
    },
  },
  computed: {
    ...mapGetters({
      rows: 'exporter/list/billsRows',
      count: 'exporter/list/billsCount',
      loading: 'exporter/list/billsLoading',
      // pagination: 'exporter/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'exporter/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
      bills:'exporter/form/bills',
    }),
    language(){
      return i18n.locale
    },
    sellerName() {
      return this.selectedRow ? this.selectedRow.name : ''
    },
    sellerPhone() {
      return this.selectedRow ? this.selectedRow.phone : ''
    },
    // printingData() {
    //   if(this.selectedRow){
    //     const items = []
    //     for (let index = 0; index < this.selectedRow.items.length; index++) {
    //       let element = this.selectedRow.items[index];
    //       element['qrImg'] = await qrcode.toDataURL('http://asyncawait.net')
    //     }
    //     return this.selectedRow.items.map(item => {
    //       return {
    //         name: item.itemId ,
    //         serialNumber: item.serialNumber,
    //         qrCode: item.qrCode,
    //       }
    //     })
    //   }
    //   return []
    // },
    hasPermissionToEdit() {
      return new ExporterPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ExporterPermissions(this.currentUser)
        .destroy;
    },

    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'exporter/list/doChangeSort',
      doChangePaginationCurrentPage: 'exporter/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'exporter/list/doChangePaginationPageSize',
      doMountTable: 'exporter/list/doMountTable',
      doDestroy: 'exporter/destroy/doDestroy',
      doDestroyAll: 'exporter/destroy/doDestroyAll',
      updateQuantityByTransaction:'exporter/destroy/updateQuantityByTransaction',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
    doClose(){
      this.dialogVisible = false
      this.print = false
    },
    presenter(row, fieldName) {
      return ExporterModel.presenter(row, fieldName);
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : '';
    },
    presenterMap(row, fieldName) {
      const val = ExporterModel.presenter(row, fieldName);
      return val[this.language]
    },
    presenterDate(row, fieldName) {
      const val = ExporterModel.presenter(row, fieldName)
      return val ? moment(row[fieldName].seconds,'X').locale(this.language).format("D MMM, YYYY") : ''; 
    },
    presenterDay(row, fieldName) {
      return moment(ExporterModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = ExporterModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.language).format('hh:mm a')
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },

    async openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'view':
          this.dialogType = 'view'
          break;
        case 'deleteAll':
          this.dialogType = 'deleteAll'
          break;
        default:
          break;
      }
      if(operation == 'print'){
        this.print = true
      }
      this.dialogVisible = true
    },
    async doDestroyAllWithConfirm(ids) {
      try {
        await this.doDestroyAll(ids);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
    async doDestroyWithConfirm(id) {
      try {
        // await this.updateQuantityByTransaction({collectionName:this.selectedRow.itemType,docId:this.selectedRow.itemId,quantity:-1})
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
  },
};
