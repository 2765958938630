//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ExporterListTable from '@/modules/exporter/components/bills/bills-list-table.vue';
import { ExporterPermissions } from '@/modules/exporter/exporter-permissions';
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n'
// import firebase from 'firebase/app';
// import 'firebase/firestore'

export default {
  name: 'app-exporter-list-page',

  components: {
    [ExporterListTable.name]: ExporterListTable,
  },

  async created() {
    // await this.getBills();
    await this.doFetchBills()
  },

  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      rows: 'exporter/list/rows',
      count: 'exporter/list/count',
      loading: 'exporter/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      
    }),
    hasPermissionToCreate() {
      return new ExporterPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('entities.exporter.label') 
    },
  },
  methods: {
    ...mapActions({
      doFetchBills: 'exporter/list/doFetchBills',
      doFetchInBackground: 'exporter/list/doFetchInBackground',
      getBills:'exporter/form/getBills',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = { status: 'exported' }
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = { status: 'exported' }
      return this.doFetchInBackground({
        filter,
      });
    },
  },
};
